/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap'); */

/* re-skin fonts */
@font-face {
    font-family: 'MontserratBlack';
    src: url('./assets/fonts/montserrat/Montserrat-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratRegular';
    src: url('./assets/fonts/montserrat/Montserrat-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('./assets/fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('./assets/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsRegular';
    src: url('./assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('./assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
}

html {
    scroll-behavior: smooth;
    /* overflow: hidden; */
}

body {
    font-family: 'PoppinsRegular', sans-serif;
    background-color: #ececec !important;
}

#navbar {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

#navbar .navbar-brand img {
    max-width: 70%;
    height: auto;
}

#navbar .navbar-nav .nav-link {
    position: relative;
}

#navbar .navbar-nav .nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: transparent;
    bottom: 0;
    left: 0;
    transition: background-color 0.3s;
}

#navbar .navbar-nav .nav-link:hover::after {
    background-color: rgb(179, 0, 0); /* Color when hovering */
}

a.nav-link.active::after {
    background-color: rgb(179, 0, 0); /* Color for the active link */
}

a.nav-link.active {
    color: rgb(179, 0, 0) !important;
}

.landing {
    font-family: 'PoppinsRegular', sans-serif;
    /* background-color: wheat; */
    min-height: 92vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing h1 {
    font-weight: 700;
}

.form-container {
    font-family: 'PoppinsRegular', sans-serif;
    /* background-color: wheat; */
    padding: 40px 0;
}

.form {
    max-width: 400px;
    margin: auto;
    /* background-color: #ffffff; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
}

.form p {
    text-align: center;
    color: #6c757d;
    margin-bottom: 30px;
}

.form-group-label {
    font-size: 16px; /* Adjust the font size as needed */
    font-weight: bold; /* Optionally adjust the font weight */
}

.checkbox {
    font-size: 15px;
    font-weight: 500;
    text-align: justify;
    cursor: pointer;
}

/* @media (max-width: 768px) {
    .form {
        max-width: 100%;
        padding: 15px;
    }
} */

.form-control {
    height: 30px; /* Adjust the height as needed */
    font-size: 14px; /* Adjust the font size as needed */
}

.form label span {
    color: red;
}

#formCheckbox label p {
    /* Your CSS styling for the <p> tag goes here */
    color: blue;
    font-size: 16px;
    /* Add other styles as needed */
}

.form .btn-submit {
    border-radius: 1.875rem !important;
}

.contact {
    font-family: 'PoppinsRegular', sans-serif !important;
}

.custom-input {
    height: 35px; /* Adjust the height as needed */
}

.button-link {
    color: black;
    text-decoration: none;
}

.footer {
    font-family: 'Cormorant Garamond', serif;
    font-family: 'Cormorant Garamond', serif;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 500;
}

.footer p {
    text-align: right;
    font-weight: 700;
}

.form-group-input-custom {
    height: 45px;
}

input[type='text']:focus {
    outline: 0;
    box-shadow: none;
}

input[type='tel']:focus {
    outline: 0;
    box-shadow: none;
}

input[type='email']:focus {
    outline: 0;
    box-shadow: none;
}

select:focus {
    outline: 0 !important;
    box-shadow: none !important;
    -webkit-tap-highlight-color: transparent !important;
}

/* react slick */
.slick-dots > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* .slick-dots > ul > li.slick-active {
    padding-right: 65px;
} */

li.slick-active > div {
    /* background-color: #005ce5; */
    background-color: #b41419;
    /* width: 60px; */
}

li > div {
    background-color: #d1d5db;
    width: 13px;
    /* margin: 10px; */
}

ul.slick-dots li {
    background-color: '#3b82f6' !important;
}

/* ul {
    list-style-type: none;
    list-style-position: inside;
    margin-left: 0px !important;
    padding-left: 0 !important;
} */

.custom-readonly {
    pointer-events: none;
    /* opacity: 0.5; */
    cursor: not-allowed;
}

.slick-dots1 {
    /* Extra small devices (phones, less than 576px) */
    @media only screen and (max-width: 575.98px) {
        bottom: -100px !important;
    }

    /* Small devices (portrait tablets and large phones, 576px and up) */
    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
        bottom: -100px !important;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
        bottom: -100px !important;
    }

    /* Large devices (desktops, 992px and up) */
    @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        bottom: -100px !important;
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        bottom: -250px !important;
    }
}

/* Responsive Styles */

/* @media (max-width: 768px) {
    .form-control {
        width: 100%;
    }
} */
